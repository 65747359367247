<template>
  <div class="pa-4">
    <v-card class="mt-2">
      <v-card-title>
        <v-row class="mx-3">
          <v-col class="v-toolbar__title">View Hasil Produksi</v-col>
          <v-spacer></v-spacer>
          <v-col md2 class="text-right">
            <v-btn color="" depressed small @click="$router.go(-1)"><v-icon small>mdi-arrow-left</v-icon> Back</v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="pa-3">
        <v-card outlined>
          <v-tabs v-model="tab" class="">
            <v-tab>Input Hasil Produk Jadi</v-tab>
            <v-tab>Defect Packaging</v-tab>
            <v-tab-item :transition="false" class="pa-3">
              <v-card elevation="0">
                <v-card-subtitle>
                  <v-row>
                    <v-col cols="3">
                      Nomor Produksi: <b>{{prodObj.prod_no}}</b>
                    </v-col>
                    <v-col>
                      Tanggal Produksi: <b>{{prodObj.production_dates | moment}}</b>
                    </v-col>
                  </v-row>
                  <div>
                    <v-radio-group v-model="selectedSize" row dense hide-details="auto">
                        <v-radio v-for="(size, idx) in bottleSize" :key="idx" :label="`${size} ml`" :value="size"></v-radio>
                    </v-radio-group>
                    <!-- {{prodVariants}}                -->
                  </div>
                </v-card-subtitle>
                <v-card-text>
                  <v-card outlined>
                  <v-simple-table fixed-header height="350px">
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left">Volume</th>
                          <th class="text-left">Nama Variant</th>
                          <th class="text-left">Jumlah Request</th>
                          <th class="text-left">Jumlah Produk Jadi</th>
                          <th class="text-left">Jumlah Produk Ekstra</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="(variant, idx) in prodVariants" :key="idx">
                          <template v-if="variant.size==selectedSize">
                          <td class="text-left">{{variant.variant_size}} ml</td>
                          <td class="text-left">{{variant.variant_name}}</td>
                          <td class="text-left">{{variant.qty_order}} botol</td>
                          <td class="text-left">{{variant.qty_produced}} botol</td>
                          <td class="text-left">
                             {{(variant.qty_produced)?Number(variant.qty_produced)-Number(variant.qty_order):0}} botol
                          </td>
                          </template>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                  </v-card>
                </v-card-text>
              </v-card>
            </v-tab-item>
            <v-tab-item :transition="false" class="pr-4">
              <v-card>
                <v-card-text>
                  <v-form ref="form" v-model="valid" lazy-validation>
                  <v-row>
                    <v-col>Category: <b>Packaging</b></v-col>
                  </v-row>
                  <v-row class="font-weight-bold">
                    <v-col cols="2">Kode Barang</v-col>
                    <v-col cols="2">Nama Barang</v-col>
                    <v-col cols="2">Jumlah Defect</v-col>
                  </v-row>
                  <v-row v-for="(defect, idx) in defects" :key="idx">
                    <v-col cols="2">
                      {{defect.material_code}}
                    </v-col>
                    <v-col cols="2">
                      {{defect.material_name}}
                    </v-col>
                    <v-col cols="2">
                      {{defect.qty_defect}}
                    </v-col>
                  </v-row>
                  </v-form>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </v-card>
      </v-card-text>
    </v-card>
    <v-snackbar top v-model="saved" color="green">Data berhasil tersimpan!</v-snackbar>
    <v-snackbar top v-model="saveError" color="red accent-2">Data gagal tersimpan! Mohon pastikan data yang akan disimpan sudah sesuai.</v-snackbar>

  </div>
</template>

<script>
import moment from 'moment'
export default {
    data: () => ({
      dialog: false,
      prodVariants: null,
      coldPressed: false,
      tab: null,
      bottleSize: [],
      selectedSize: null,
      saved: false,
      saveError: false,
      rawmaterials: [],
      defects: [],
      reqRule: [v => !!v || 'Harus diisi!'],
      valid: true
    }),

    computed: {
      miscCodes() {
        return this.$store.state.misccode.objs
      },
      prodObj() {
        return this.$store.state.production.obj
      }
    },

    watch: {
    },

    filters: {
        moment: function (date) {
            return moment(date).format('D MMM YYYY');
        },
    },

    async mounted() {
      this.initialize()
      const docs = (await this.$store.dispatch('rawmaterial/getObjs')).data.data
      this.rawmaterials = docs.filter(el => {return el.category_code=='LNB'})
      console.log(this.rawmaterials)
      this.addDefect()
    },

    methods: {
        addDefect() {
          this.defects.push({
            prod_no: this.prodObj.prod_no,
            material_code: null,
            qty_defect: null
          })
        },
        removeDefect(idx) {
          this.defects.splice(idx, 1)
        },
        async initialize() {
            this.prodVariants = (await this.$store.dispatch('production/getProductionVariants', this.prodObj.prod_no)).data.data
            console.log(this.prodVariants)
            this.defects = (await this.$store.dispatch('production/getProductionDefects', this.prodObj.prod_no)).data.data
            console.log(this.defects)
        },
        async save() {
          await this.$refs.form.validate();
          if (!this.valid) return;
          const defDocs = await this.$store.dispatch('production/postProductionDefects', {data:this.defects})
          console.log(defDocs)

          let payload = []
          this.prodVariants.forEach(obj => {
            if (obj.qty_produced==null) obj.qty_produced = 0
            payload.push({
              prod_no: this.prodObj.prod_no,
              variant_code: obj.code,
              qty_order: obj.qty_request,
              qty_produced: obj.qty_produced
            })
          })

          payload = {variants:payload}
          const docs = await this.$store.dispatch('production/postProductionVariants', payload)
          console.log(docs)
          if (docs.status==200 || docs.status==201) {
            let prodObj = JSON.parse(JSON.stringify(this.prodObj))
            prodObj.status = 'FINISHPROD'
            prodObj.filling_end = Date.now()
            this.clean(prodObj)
            const prodDocs = await this.$store.dispatch('production/putObj', prodObj)
            await this.$store.dispatch('production/postProductionDefects', this.defects)
            if (prodDocs.status==200 || prodDocs.status==201) this.saved = true
            else this.saveError = true
            this.$router.go(-1)
          }
        },
        clean(obj) {
          for (var propName in obj) {
            if (obj[propName] === null || obj[propName] === undefined) {
              delete obj[propName];
            }
          }
          return obj
        }



    }    
}
</script>

<style>
  .v-label {
    font-size: 0.9rem;
  }
</style>